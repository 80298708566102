import moment from 'moment-timezone';

export const updateObject = (oldObject, updatedProperties) =>{
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules)=>{
    let isValid = true;
    let errorString = '';
    if ( !rules ) {
        return true;
    }

    if ( rules.minLength ) {
        if(value.trim() !=='' &&  (value.length < rules.minLength)){
            errorString = 'This field is must be at least '+rules.minLength+' characters long.';
            isValid = false;
        }
    }

    if ( rules.maxLength ) {
        if(value.length >= rules.maxLength){
            errorString = 'This field is must be less than '+rules.minLength+' characters long.';
            isValid = false;
        }
    }

    if ( typeof rules.min !=='undefined' ) {
        if(parseFloat(value) < parseFloat(rules.min)){
            errorString = 'This number must be  '+ rules.min +' or greater.';
            isValid = false;
        }
    }
    if ( rules.max ) {
        if(parseFloat(value) >= parseFloat(rules.max)){
            errorString = 'This number must be less than '+ rules.max +'.';
            isValid = false;
        }
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if(!pattern.test( value )){
            errorString = 'This must be a valid email address.';
            isValid = false;
        }
    }

    if ( rules.isUrl && value.trim() !=='') {
        const pattern =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if(!pattern.test( value )){
            errorString = 'This must be a valid website address.';
            isValid = false;
        }
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;

        if(!pattern.test( value )){
            errorString = 'This must be a number.';
            isValid = false;
        }
    }

    if(rules.isDate){
        const enteredDate = moment(value, 'yyyy-M-D', true);
        if(!enteredDate.isValid()){
            errorString = 'Please enter a valid date. (YYYY-MM-DD)';
            isValid = false;
        }
    }

    if(rules.isTime){
        const enteredDate = moment(value, 'HH:mm', true);
        if(!enteredDate.isValid()){
            errorString = 'Please enter a valid time. (HH:MM) 24hr';
            isValid = false;
        }
    }

    if ( rules.required ) {

        if(value.trim() === ''){
            errorString = 'This field is required.';
            isValid = false;
        }
    }

    return {isValid: isValid, errorMessage: errorString};
};

export const checkIsFormValid = ( updatedControls ) =>{
    //check to make sure all fields are valid
    let formIsValid = true;
    for(let inputIdentifier in updatedControls){
        formIsValid = !updatedControls[inputIdentifier].elementConfig.error && formIsValid;
    }
    return formIsValid;
};

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September','October','November','December'];
const chemicalNames = {
    'Chlorine_dichlor' :'Chlorine (Dichlor)',
    'Chlorine_trichlor' :'Chlorine (Trichlor)',
    'Chlorine_73' :'Chlorine (cal-hypo 73%)',
    'Chlorine_65' :'Chlorine (cal-hypo 65%)',
    'Chlorine_53' :'Chlorine (cal-hypo 53%)',
    'Chlorine_48' :'Chlorine (cal-hypo 48%)',
    'Chlorine_liquid_15':'Chlorine (Sodium-hypo 15%)',
    'Chlorine_liquid_12_5':'Chlorine (Sodium-hypo 12.5%)',
    'Chlorine_liquid_12':'Chlorine (Sodium-hypo 12%)',
    'Chlorine_liquid_10_5':'Chlorine (Sodium-hypo 10.5%)',
    'Chlorine_liquid_9':'Chlorine (Sodium-hypo 9.2%)',
    'Salt':'Salt Water (Chlorine Generator)',
    'Bromine':'Bromine'
};

const laMotteDiskNames = {
    'DISC_103' :'4328',
    'DISC_203' :'4329',
    'DISC_303' :'4330',
    'DISC_501' :'4334',
    'DISC_601' :'4335',
};

export const setHeaderFieldsFormat = (field, helperData) =>{
    switch (field.format) {
        case 'dateTime':
            return {id: field.id,
            label: field.label,
            format: (value, row) => formatDateTime(value, row)
        };
        case 'date': return {id: field.id,
            label: field.label,
            format: (value) => formatDate(value)

        };
        case 'month': return {id: field.id,
            label: field.label,
            format: (value) => value !==null? monthNames[value-1] : 'Year-Round'
        };
        case 'distance': return {
            id: field.id,
            label: field.label,
            format: (value) => value !==null ? calcFormattedDistance(value) : '',
            warning:(value) => value !==null ? displayDistanceWarning(value) : false,
        };
        case 'isSeasonal' : return {
            id: 'isSeasonal',
            label: 'Open',
            format: (value) => value==='F' ?'Year-round': 'Seasonal'
        };
        case 'automationValues':
            return {id: field.id,
                label: field.label,
                warning:(value, row) => automationInAlert( value, row, field.id),
                format: (value, row) => formatAmountPlusUnits( value, row, field.id)
            };
        case 'rangeAlert':
            return {id: field.id,
                label: field.label,
                highAlert:(value, row) => chemicalRangeHighAlert( value, row, field.id, helperData),
                lowAlert:(value, row) => chemicalRangeLowAlert( value, row, field.id, helperData),
              //  format: (value, row) => formatAmountPlusUnits( value, row, field.id)
            };
        case 'sourceType': return {id: field.id, label: field.label,  format: (value) => formatSourceTypeDisplay(value) };
        case 'boolean': return {id: field.id, label: field.label,  format: (value) =>  value ? (value==='T'?'Yes':'No'): null};
        case 'temp': return {id: field.id, label: field.label,  format: (value) =>  value ? (value+'°'): null};
        case 'chemical': return {id: field.id, label: field.label,  format: (value) =>  formatChemicalDisplay(value)};
        default: return {id: field.id, label: field.label,  };
    }
};
const displayDistanceWarning = value =>{
    const WARNING_DISTANCE = 0.0568 ; // distance saved as miles , roughly 300 feet
    return (parseFloat(value) > WARNING_DISTANCE );
}

 const automationInAlert = (value, row, id) =>{
    return (row[id] && row[id]['alarm']) ? row[id]['alarm']==='alarm' : false;
};

const chemicalRangeHighAlert = (value, row, id, highLowRange) =>{
    return  (highLowRange.highAlert !== null && parseFloat(value)> parseFloat(highLowRange.highAlert))  ;
};

const chemicalRangeLowAlert = (value, row, id, highLowRange) =>{
    return  (highLowRange.lowAlert !== null && parseFloat(value) < parseFloat(highLowRange.lowAlert)) ;
};

const calcFormattedDistance = value =>{
    const MILE = 5280;
    const distance = parseFloat(value) * MILE;
    const feet = distance % MILE;
    const miles = parseFloat(distance / MILE).toFixed(2);

    let formattedString ='';
    if(distance > (MILE / 4)){
        formattedString = miles + ' miles';
    }else{
        formattedString = formatNumberWithCommas(feet) + ' feet';
    }

    return formattedString;
};

export const formatUserType = value =>{
    switch(value){
        case 'USER': return 'User';
        case 'ADMIN': return 'Admin';
        case 'SERVICE_TECH': return 'Service Tech';
        default: return 'N/A';
    }
}

export const formatDate = value =>{
    try{
         return moment(value).format('M/D/yyyy');
    }catch{
        return "N/A";
    }
};

export const formatDateTime = (value, row) =>{
    try{
      const tz = (row && row['timezone'] )? row['timezone']: 'America/New_York';
      return moment.tz(value.replace(/\s/, 'T')+'Z' , tz).format('M/D/yyyy h:mm a z');
    }catch{
        return "N/A";
    }
};


export const formatTime = ( value) =>{
    return moment(value, "HH:mm:ss").format("hh:mm A");
};

export const formatPhone = value =>{
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
};

export const formatDecimal= (x, places)=>{
    let n = parseFloat(x);
    return n.toFixed(places);
}

export const  formatNumberWithCommas = (x) =>{
    return parseInt(x).toLocaleString();
};

export const formatChemicalDisplay = ( code )=>{
   return chemicalNames[code]? chemicalNames[code]: code;
};

export const formatMonthNameDisplay= (monthCode)=>{
    return monthNames[monthCode -1] ? monthNames[monthCode -1]: monthCode;
};

export const formatSourceTypeDisplay = ( code )=>{
    return laMotteDiskNames[code]? laMotteDiskNames[code]: code;
};

export const formatAmountPlusUnits = (value, row, id) =>{
    try{
        const units =  row[id]['units']? row[id]['units'] :'';
        return value + ' '+units;
    }catch{
        return "N/A";
    }
};

export const updateControlState = (context, value, controlName) =>{

    const validityObj =  checkValidity( value, context.state.controls[controlName].validation );
    const updatedElementConfig = {
        ...context.state.controls[controlName].elementConfig,
        helperText: validityObj.errorMessage ,
    };
    const updatedConfig = {
        ...updatedElementConfig,
        error: !validityObj.isValid
    };

    const updatedControls = {
        ...context.state.controls,
        [controlName]: {
            ...context.state.controls[controlName],
            elementConfig: updatedConfig,
            value: value,
            touched:true,
        }
    };

    //check to make sure all fields are valid
    let formIsValid = true;
    for(let inputIdentifier in updatedControls){
        const element = updatedControls[inputIdentifier];
        if(element.elementConfig.error || (element.validation.required && element.value ==='' && !element.touched) ){
            formIsValid = false;
        }
    }

    context.setState( updateObject(context.state, { controls: updatedControls , formIsValid: formIsValid }) );

};