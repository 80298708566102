export const NOT_AUTHENTICATED_ERROR_CODE = 401;
export const FORBIDDEN_ERROR = 403;

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const COMPANY_SEARCH_START ='COMPANY_SEARCH_START';
export const COMPANY_SEARCH_SUCCESS ='COMPANY_SEARCH_SUCCESS';
export const COMPANY_SEARCH_FAILED ='COMPANY_SEARCH_FAILED';
export const COMPANY_SEARCH_RELOAD_DATA = 'COMPANY_SEARCH_RELOAD_DATA';
export const COMPANY_SEARCH_FILTER_SUCCESS ='COMPANY_SEARCH_FILTER_SUCCESS';
export const COMPANY_SEARCH_TOGGLE_DELETED_SUCCESS = 'COMPANY_SEARCH_TOGGLE_DELETED_SUCCESS';

export const COMPANY_INFO_START ='COMPANY_INFO_START';
export const COMPANY_INFO_SUCCESS ='COMPANY_INFO_SUCCESS';
export const COMPANY_INFO_FAILED ='COMPANY_INFO_FAILED';
export const COMPANY_INFO_SET_ID ='COMPANY_INFO_SET_ID';


export const COMPANY_CREATE_START = 'COMPANY_CREATE_START';
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const COMPANY_CREATE_FAILED = 'COMPANY_CREATE_FAILED';

export const COMPANY_GET_CHEM_DEFAULTS_START = 'COMPANY_GET_CHEM_DEFAULTS_START';
export const COMPANY_GET_CHEM_DEFAULTS_SUCCESS = 'COMPANY_GET_CHEM_DEFAULTS_SUCCESS';
export const COMPANY_GET_CHEM_DEFAULTS_FAILED = 'COMPANY_GET_CHEM_DEFAULTS_FAILED';

export const COMPANY_UPDATE_CHEM_DEFAULTS_START='COMPANY_UPDATE_CHEM_DEFAULTS_START';
export const COMPANY_UPDATE_CHEM_DEFAULTS_SUCCESS='COMPANY_UPDATE_CHEM_DEFAULTS_SUCCESS';
export const COMPANY_UPDATE_CHEM_DEFAULTS_FAILED='COMPANY_UPDATE_CHEM_DEFAULTS_FAILED';

export const UPDATE_DATA_START = 'UPDATE_DATA_START';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_FAILED = 'UPDATE_DATA_FAILED';

export const UX_JSON_START = 'UX_JSON_START';
export const UX_JSON_SUCCESS = 'UX_JSON_SUCCESS';
export const UX_JSON_FAILED = 'UX_JSON_FAILED';
export const UX_JSON_CLEAR_CONTROLS = 'UX_JSON_CLEAR_CONTROLS';

export const UX_SAVE_SEARCH_FILTERS = 'UX_SAVE_SEARCH_FILTERS';

export const USER_SEARCH_START ='USER_SEARCH_START';
export const USER_SEARCH_SUCCESS ='USER_SEARCH_SUCCESS';
export const USER_SEARCH_FAILED ='USER_SEARCH_FAILED';
export const USER_SEARCH_FILTER_SUCCESS ='USER_SEARCH_FILTER_SUCCESS';
export const USER_SEARCH_TOGGLE_DELETED_SUCCESS = 'USER_SEARCH_TOGGLE_DELETED_SUCCESS';

export const USER_INFO_START ='USER_INFO_START';
export const USER_INFO_SUCCESS ='USER_INFO_SUCCESS';
export const USER_INFO_FAILED ='USER_INFO_FAILED';
export const USER_INFO_SET_ID ='USER_INFO_SET_ID';

export const USER_POOL_GROUP_START ='USER_POOL_GROUP_START';
export const USER_POOL_GROUP_SUCCESS ='USER_POOL_GROUP_SUCCESS';
export const USER_POOL_GROUP_FAILED ='USER_POOL_GROUP_FAILED';

export const POOL_SEARCH_START ='POOL_SEARCH_START';
export const POOL_SEARCH_SUCCESS ='POOL_SEARCH_SUCCESS';
export const POOL_SEARCH_FAILED ='POOL_SEARCH_FAILED';
export const POOL_SEARCH_FILTER_SUCCESS ='POOL_SEARCH_FILTER_SUCCESS';

export const POOL_GET_LOG_START ='POOL_GET_LOG_START';
export const POOL_GET_LOG_SUCCESS ='POOL_GET_LOG_SUCCESS';
export const POOL_GET_LOG_FAILED ='POOL_GET_LOG_FAILED';

export const POOL_GET_CHEMICAL_EXPORT_START ='POOL_GET_CHEMICAL_EXPORT_START';
export const POOL_GET_CHEMICAL_EXPORT_SUCCESS ='POOL_GET_CHEMICAL_EXPORT_SUCCESS';
export const POOL_GET_CHEMICAL_EXPORT_FAILED ='POOL_GET_CHEMICAL_EXPORT_FAILED';

export const POOL_INFO_SET_ID = 'POOL_INFO_SET_ID';
export const POOL_INFO_START = 'POOL_INFO_START';
export const POOL_INFO_FAILED = 'POOL_INFO_FAILED';
export const POOL_INFO_SUCCESS = 'POOL_INFO_SUCCESS';

export const POOL_CHECKLIST_SET_ID = 'POOL_CHECKLIST_SET_ID';
export const POOL_CHECKLIST_DATES_START = 'POOL_CHECKLIST_DATES_START';
export const POOL_CHECKLIST_DATES_FAILED = 'POOL_CHECKLIST_DATES_FAILED';
export const POOL_CHECKLIST_DATES_SUCCESS = 'POOL_CHECKLIST_DATES_SUCCESS';

export const POOL_ADD_POOL_START = 'POOL_ADD_POOL_START';
export const POOL_ADD_POOL_SUCCESS = 'POOL_ADD_POOL_SUCCESS';
export const POOL_ADD_POOL_FAILED = 'POOL_ADD_POOL_FAILED';

export const POOL_GET_TEST_FIELD_DEFAULTS_START ='POOL_GET_TEST_FIELD_DEFAULTS_START';
export const POOL_GET_TEST_FIELD_DEFAULTS_SUCCESS = 'POOL_GET_TEST_FIELD_DEFAULTS_SUCCESS';
export const POOL_GET_TEST_FIELD_DEFAULTS_FAILED = 'POOL_GET_TEST_FIELD_DEFAULTS_FAILED';

export const POOL_UPDATE_TEST_FIELD_DEFAULTS_START ='POOL_UPDATE_TEST_FIELD_DEFAULTS_START';
export const POOL_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS = 'POOL_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS';
export const POOL_UPDATE_TEST_FIELD_DEFAULTS_FAILED = 'POOL_UPDATE_TEST_FIELD_DEFAULTS_FAILED';

export const POOL_GET_AUTOMATION_DATA_START = 'POOL_GET_AUTOMATION_DATA_START';
export const POOL_GET_AUTOMATION_DATA_SUCCESS = 'POOL_GET_AUTOMATION_DATA_SUCCESS';
export const POOL_GET_AUTOMATION_DATA_FAILED = 'POOL_GET_AUTOMATION_DATA_FAILED';

export const POOL_RESET_USER_MESSAGE = 'POOL_RESET_USER_MESSAGE';

export const REPORT_GET_REPORT_START = 'REPORT_GET_REPORT_START';
export const REPORT_GET_REPORT_FAILED = 'REPORT_GET_REPORT_FAILED';
export const REPORT_GET_REPORT_SUCCESS = 'REPORT_GET_REPORT_SUCCESS';
export const REPORT_SET_REPORT_ID = 'REPORT_SET_REPORT_ID';

export const CORP_GET_CHEM_DEFAULTS_START = 'CORP_GET_CHEM_DEFAULTS_START';
export const CORP_GET_CHEM_DEFAULTS_SUCCESS = 'CORP_GET_CHEM_DEFAULTS_SUCCESS';
export const CORP_GET_CHEM_DEFAULTS_FAILED = 'CORP_GET_CHEM_DEFAULTS_FAILED';

export const CORP_UPDATE_CHEM_DEFAULTS_START = 'CORP_UPDATE_CHEM_DEFAULTS_START';
export const CORP_UPDATE_CHEM_DEFAULTS_SUCCESS = 'CORP_UPDATE_CHEM_DEFAULTS_SUCCESS';
export const CORP_UPDATE_CHEM_DEFAULTS_FAILED = 'CORP_UPDATE_CHEM_DEFAULTS_FAILED';

export const CORP_GET_TEST_FIELD_DEFAULTS_START = 'CORP_GET_TEST_FIELD_DEFAULTS_START';
export const CORP_GET_TEST_FIELD_DEFAULTS_SUCCESS = 'CORP_GET_TEST_FIELD_DEFAULTS_SUCCESS';
export const CORP_GET_TEST_FIELD_DEFAULTS_FAILED = 'CORP_GET_TEST_FIELD_DEFAULTS_FAILED';

export const CORP_UPDATE_TEST_FIELD_DEFAULTS_START = 'CORP_UPDATE_TEST_FIELD_DEFAULTS_START';
export const CORP_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS = 'CORP_UPDATE_TEST_FIELD_DEFAULTS_SUCCESS';
export const CORP_UPDATE_TEST_FIELD_DEFAULTS_FAILED = 'CORP_UPDATE_TEST_FIELD_DEFAULTS_FAILED';

export const CORP_GET_ALL_DASHBOARD_DATA_START ='CORP_GET_ALL_DASHBOARD_DATA_START';
export const CORP_GET_ALL_DASHBOARD_DATA_SUCCESS ='CORP_GET_ALL_DASHBOARD_DATA_SUCCESS';
export const CORP_GET_ALL_DASHBOARD_DATA_FAILED ='CORP_GET_ALL_DASHBOARD_DATA_FAILED';

export const CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_START ='CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_START';
export const CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_SUCCESS ='CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_SUCCESS';
export const CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_FAILED ='CORP_GET_ALL_AUTOMATION_DASHBOARD_DATA_FAILED';

export const CORP_STAFF_SEARCH_START ='CORP_STAFF_SEARCH_START';
export const CORP_STAFF_SEARCH_SUCCESS ='CORP_STAFF_SEARCH_SUCCESS';
export const CORP_STAFF_SEARCH_FAILED ='CORP_STAFF_SEARCH_FAILED';
export const CORP_STAFF_SEARCH_FILTER_SUCCESS ='CORP_STAFF_SEARCH_FILTER_SUCCESS';
export const CORP_STAFF_SEARCH_TOGGLE_DELETED_SUCCESS = 'CORP_STAFF_SEARCH_TOGGLE_DELETED_SUCCESS';

export const CORP_TEMPLATE_SEARCH_START = 'CORP_TEMPLATE_SEARCH_START';
export const CORP_TEMPLATE_SEARCH_SUCCESS = 'CORP_TEMPLATE_SEARCH_SUCCESS';
export const CORP_TEMPLATE_SEARCH_FAILED = 'CORP_TEMPLATE_SEARCH_FAILED';

export const CORP_TEMPLATE_SET_ID = 'CORP_TEMPLATE_SET_ID';
export const CORP_TEMPLATE_SET_DATA = 'CORP_TEMPLATE_SET_DATA';
